@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Lato", sans-serif;
}

body * {
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #dde3ee;
}

::-webkit-scrollbar-thumb {
  background: #6c7689;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #003366;
}

.react-calendar__navigation__label {
  pointer-events: none;
}

.react-calendar__navigation__label__labelText {
  font-family: Lato;
  font-weight: 700;
  font-size: 24px;
  color: #003366;
}

.schedule .react-calendar__month-view__weekdays {
  height: 54px;
}

.schedule .react-calendar__navigation {
  padding: 0 32px;
  margin-bottom: 32px;
}

.schedule .react-calendar__navigation__label {
  flex-grow: 0 !important;
  padding: 0 16px !important;
}

.schedule .react-calendar__month-view__weekdays__weekday {
  display: flex;
  justify-content: center;
  align-items: center;
}

.schedule .react-calendar__navigation__arrow {
  padding: 0 !important;
  min-width: auto !important;
}

.react-calendar__month-view__weekdays__weekday {
  font-family: Lato;
  font-weight: 700;
  font-size: 14px;
  color: #003366;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__tile--now,
.react-calendar__tile--active {
  background: none !important;
}

.calendar .react-calendar__tile--active > div {
  background: #006edc;
  color: #fff;
}

.schedule .react-calendar__tile--active > div {
  background: #e5f2ff;
  border: 1px solid #0066cc;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: none;
}

.react-calendar__tile:disabled {
  background: none !important;
  pointer-events: none;
}

